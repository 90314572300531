import { setupKeurtApiClient } from "~/instances/KeurtApiClient";

export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig();
    const url = config.public.apiBase;
    try {
        setupKeurtApiClient(url);
    } catch {
        console.log("Already setup client")
    }
    return;
});
  