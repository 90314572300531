import axios from "axios";
import type { AxiosInstance } from 'axios'

let keurtApiClient: AxiosInstance | null = null;

const setupKeurtApiClient = (baseURL: string) => {
  if (keurtApiClient) {
    throw new Error("keurtApiClient is already initialized. Setup can only be called once.");
  }

  keurtApiClient = axios.create({ baseURL });

  keurtApiClient.interceptors.request.use(
    (config: any) => {
      const tokenCookie = useCookie("access_token");
      if (tokenCookie.value !== undefined && config.headers) {
        config.headers["Authorization"] = `Bearer ${tokenCookie.value}`;
      }
      return config;
    },
    (error:any) => {
      return Promise.reject(error);
    }
  );
};

const getKeurtApiClient = (): AxiosInstance => {
  if (!keurtApiClient) {
    throw new Error("keurtApiClient is not set up. Please call setupKeurtApiClient() first.");
  }
  return keurtApiClient;
};


const login = async (username: string, password: string) => {
  try{
    const client = getKeurtApiClient();
    const authDataResponse = await client.post("/login", {
      email: username,
      password: password,
    });

    if (authDataResponse.status === 200) {
      const accessToken = useCookie("access_token");
      accessToken.value = authDataResponse.data.accessToken;
      sessionStorage.setItem("user", JSON.stringify(authDataResponse.data.user));
      return true;
    }
    return false;
  }
  catch {
    return false;
  }
};

export { setupKeurtApiClient, getKeurtApiClient, login };
