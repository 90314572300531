export default {
    root: ({ props }) => ({
        class: [
            //Font
            'text-xs',

            //Alignments
            'inline-flex items-center justify-center',

            //Spacing
            'px-[0.4rem] py-1',

            //Shape
            {
                'rounded-md': !props.rounded,
                'rounded-full': props.rounded
            },

            //Colors
            {
                'text-primary-highlight-inverse bg-primary-highlight': props.severity == null || props.severity == 'primary',
                'text-surface-0 dark:text-green-300 bg-[#65C710] dark:bg-green-500/20': props.severity == 'success',
                'text-surface-0 dark:text-surface-300 bg-surface-100 dark:bg-surface-500/20': props.severity == 'secondary',
                'text-surface-0 dark:text-blue-300 bg-[#8B99AF] dark:bg-blue-500/20': props.severity == 'info',
                'text-surface-0 dark:text-orange-300 bg-[#FFB200] dark:bg-orange-500/20': props.severity == 'warning',
                'text-surface-0 dark:text-red-300 bg-[#CF1024] dark:bg-red-500/20': props.severity == 'danger',
                'text-surface-0 dark:text-surface-900 bg-surface-900 dark:bg-surface-0': props.severity == 'contrast'
            }
        ]
    }),
    value: {
        class: 'leading-normal'
    },
    icon: {
        class: 'mr-1 text-sm'
    }
};
