import validate from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45setup_45global from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/middleware/0.setup.global.ts";
import _1_45auth_45global from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/middleware/1.auth.global.ts";
import manifest_45route_45rule from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45setup_45global,
  _1_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}