import { default as createPasswordhixHUWqkjMMeta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/createPassword.vue?macro=true";
import { default as indexDqfMNSWF0HMeta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/index.vue?macro=true";
import { default as locations8E6aublpNnMeta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/locations.vue?macro=true";
import { default as login9rTVoJplr6Meta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/login.vue?macro=true";
import { default as productswMgmQYTPjHMeta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/products.vue?macro=true";
import { default as requestPasswordResetg7qfhNcpw2Meta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/requestPasswordReset.vue?macro=true";
import { default as summarybobb01nHV7Meta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/summary.vue?macro=true";
import { default as usersgkGFiWjmKYMeta } from "/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/users.vue?macro=true";
export default [
  {
    name: "createPassword",
    path: "/createPassword",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "requestPasswordReset",
    path: "/requestPasswordReset",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/requestPasswordReset.vue").then(m => m.default || m)
  },
  {
    name: "summary",
    path: "/summary",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/summary.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/home/runner/work/Keurt.Frontend/Keurt.Frontend/pages/users.vue").then(m => m.default || m)
  }
]