export default defineNuxtRouteMiddleware((to, from) => {
  const token = useCookie("access_token");

  if (to.params.id === "1") {
    throw createError({
      statusCode: 404,
      statusMessage: "Pagina niet gevonden",
    });
  }

  if (token.value === undefined 
      && to.path !== "/login" 
      && to.path !== "/createPassword" 
      && to.path !== "/requestPasswordReset") {
    return navigateTo("/login");
  }

  return;
});
